import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import BookingForm from './BookingForm'
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
  } from "react-router-dom";
import AdminView from './component/AdminView';



  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <App></App>
      ),
    },
    {
      path: "/admin",
      element: (
        <AdminView></AdminView>
      ),
    },
  ]);
  
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    
    <RouterProvider router={router} />

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
