

import { useEffect, useState } from 'react';
import axios from 'axios'
import { Container, Row, Col, Modal,Form, InputGroup, Button, FormControl, Alert } from 'react-bootstrap';
import config from '../config.json'
import Wave from 'react-wavify';
import LoadingAlert from '../LoadingAlert'
import '../App.css';
import ModalReset from './ModalReset';
const devices_info=config
function AdminView(){
    const params = new URLSearchParams(document.location.search);
    
    const [devicereset, setResetDevice]=useState("")

    const [show, setShow]=useState(false)
    
    const [level_pu, SetLevel]= useState([]);

    const [showLoading, setShowLoading]= useState(false);

    const [admin, setAdmin]=useState(false)
    const [indexIsland, setIndexIsland]=useState(0)
    const [indexDevice, setindexDevice]=useState(0)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const apiKey = process.env.REACT_APP_PSW_INT  


    function trovaIsolaConId( idCercato) {
        console.log(idCercato)
        for (let i=0; i<devices_info.isole.length; i++){
          for(let j=0; j<devices_info.isole[i].devices.length; j++){
            if (devices_info.isole[i].devices[j].id === idCercato) {
              return [i, j]
              
          }}}}
          
          function Door(island, index){
            let devices= devices_info.isole[island]
            let item=devices.devices[index]
            const params = new URLSearchParams(document.location.search);
            if(level_pu[index*3] || admin){
              axios.post("https://fleet85.smartme.io:40148/api/v1/system/datastore/query", 
              {"action": "pub",
              "args": {
                  "items": [{
                      "channel": item.id+"_prc",
                      "message": "1"
                  }
                  ]
              }
            },
               {
                headers:{
                  "Authorization": apiKey
                }
              }).then(e=>{
                
              }).catch(e=>{
               
              })
              setShowLoading(true)
      
              setTimeout(()=>{
                setShowLoading(false)
              },10000)
            }
          }
        function OpenModal(island, index){
            let devices= devices_info.isole[island]
            let item=devices.devices[index]
            setResetDevice(item.id+"_zerolevel")
            setShow(true)
          }

    useEffect(()=>{
        try{
            let single_device= params.get("id");
            let island= params.get("island")
            let devices=[]
            let indexSingle
            if(single_device!=null){
              console.log("single id")
              console.log(single_device)
              let info= trovaIsolaConId(single_device)
              console.log(info)
              if(info!=null){
                setIndexIsland(info[0])
                indexSingle=info[1]
                setindexDevice(info[1])
                devices=devices_info.isole[info[0]]
              }
            }
            else{
              devices= devices_info.isole[params.get("island")]
              setIndexIsland(params.get("island"))
              if(params.get("id")!=undefined){
                setindexDevice(params.get("id"))
              }
            }

            
         
           
            console.log(devices)
            let keys_redis=[]
            devices.devices.map(item=>{
              keys_redis.push(item.id+"_level")
              keys_redis.push(item.id+"_zerolevel")
              keys_redis.push(item.id+"_counter")
            })
            axios.post("https://fleet85.smartme.io:40148/api/v1/system/datastore/query", 
            {"action": "get", "args": {"key": keys_redis, "type": "appl"}},
            {
              headers:{
                "Authorization": apiKey
              }
            }).then(e=>{
              console.log(e.data.arancino.datastore.result)
              
              try{
                SetLevel(e.data.arancino.datastore.result)

                 
              }catch(e){
                
              }
             
            }).catch(e=>{
              
            })
          
 
          }catch(e){
            console.log("error", e)
          }
    },[])

    function mappingValue(x,  in_min,  in_max,  out_min,  out_max) {
        return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
    }
    function getLevel(index){

        try{
          let tmp= mappingValue(level_pu[index*3], level_pu[index*3+1],33.0, 0.0,100.0).toFixed(0)
          console.log(tmp, index)
          console.log(level_pu[index*3], level_pu[index*3+1])
          if(tmp>100){
           
            return 100
            
          }
          else if(tmp<0){
            return 0
          }
          else if(isNaN(tmp)){
            return 0
          }
          else{
            return tmp
          }
        }
        catch(e){
          return 0
        }
  
      }
      function OpenModal(island, index){
        let devices= devices_info.isole[island]
        let item=devices.devices[index]
        setResetDevice(item.id+"_zerolevel")
        setShow(true)
      }

    return (
        <div className="App">
        <header >
          <img width={"100px"}src="poopick.png"></img>
          <h3>Environment  and Waste Management</h3>
        </header>
        <body>
        <Container>
        <Row>
          <strong><h1 style={{color:'grey'}}>{devices_info.isole[indexIsland]?.nome}</h1></strong>
        </Row>
        <Row>
          {devices_info.isole[indexIsland].devices!=undefined ? devices_info.isole[indexIsland]?.devices.map((item, index)=>(
            <Col>
              <p class="counter">Aperture Settimanali {level_pu[index*3+2]!= undefined ?level_pu[index*3+2] :0}</p>
              <div class="bodyApp">
                <div class="Ocean">
                  <Wave fill={item.enable ? "forestgreen": "gray"}
                        paused={false}
                  
                        style={{ display: 'flex', fill:"forestgreen" }}
                        options={{
                          height: 100,
                          amplitude: 10,
                          speed: 0.25,
                          points: 5,
                          fill: "forestgreen"
                        }}/>
                  <div class={item?.enable ? "Level": "Level_disabled"}  style={{height:item.enable ? getLevel(index)+"%": "10%"}} ></div> 
                </div>
              </div>
              <p class="precent">{item?.enable ? getLevel(index): ""}%</p>
              <div class="ButtonDiv">
                <button disabled={false} class="Button"  onClick={e=>{Door(indexIsland, index)}}>
                  <div className='BtnDiv'>
                   <img width={"30px"} src='/power.png'/>OPEN
                  </div>
                </button>
                <div className='separate'></div>
                <button disabled={false} class=" delete"  onClick={e=>{OpenModal(indexIsland, index)}}>
                  <div className='BtnDiv'>
                   RESET
                  </div>
                </button>
                <h1 style={{padding:"10px", color:"forestgreen"}}>{item?.nome}</h1>
              </div>
            </Col>
          )) : <>
            
          </>}
        </Row>
      </Container>
      </body>
      <ModalReset show={show} handleClose={handleClose} deviceReset={devicereset}></ModalReset>
      <LoadingAlert show={showLoading}/>
      </div>
    )
}


export default AdminView
