

import { useEffect, useState } from "react"
import axios from "axios"
import {  Modal,Form, InputGroup, Button, FormControl } from 'react-bootstrap';

function ModalReset({show, deviceReset, handleClose}){
    const apiKey = process.env.REACT_APP_PSW_INT  
    const ADMINPATH= process.env.REACT_APP_ADMIN_PATH || "/admin"
    const [pswReset, setPass]=useState("")
    const PSW_RESET= process.env.REACT_APP_PSW_RESET  || "ewm"
    
    function ResetConfig(){
  
        axios.post("https://fleet85.smartme.io:40148/api/v1/system/datastore/query", 
        {"action": "del", "args": {"key":  [deviceReset], "type": "appl"}},
         {
          headers:{
            "Authorization": apiKey
          }
        }).then(e=>{
          console.log("done")
        }).catch(e=>{
          console.log(e)
        })
      }
      function PasswordToReset(val){
        setPass(val)
      }
    function CheckPsw(){
        console.log(PSW_RESET)
        if(pswReset==PSW_RESET){
  
          ResetConfig()
          handleClose()
  
        }else{
          alert("Password is not Correct")
         
        }
      }
    return (
        <Modal show={show} >
        <Modal.Header closeButton={false}>
          <Modal.Title>Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>Insert Password to delete the <strong>Calibration</strong>


      <Form>
        <Form.Group >
          <Form.Label>Inserisci la Password</Form.Label>
          <InputGroup>
            <InputGroup.Text>🔒</InputGroup.Text>
            <FormControl
            id="passwordInput"
            type="password"
            value={pswReset}
            onChange={e=>{PasswordToReset(e.target.value)}}
            invalid={true} // Mostra il bordo rosso se c'è un errore
            placeholder="Password"
            />

          </InputGroup>
        </Form.Group>
        </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={e=>{CheckPsw()}}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    )
}



export default ModalReset